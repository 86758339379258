<template>
  <div class="br1111222" ref="vlbox">
    <div class="index_main" ref="index_main">
      <div class="index_right">
        <p class="search">
          <el-select v-model="searchForm.state" clearable  v-if="permissoin.includes('query')" size="mini">
            <el-option
              v-for="item in option1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input  v-if="permissoin.includes('query')"
            type="text"
            size="mini" clearable
            v-model="searchForm.name"
            placeholder=" 请输入关键字"
          ></el-input>
          <button
            class="btn1"  v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button class="btn2"  v-if="permissoin.includes('add')" @click="modal = 1;modalState=0">
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column label="开票类型">
              <template slot-scope="scope">
                <span v-if="scope.row.type == 1" >项目</span>
                <span v-if="scope.row.type == 2" >代理商</span>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="projectName" label="开票对象" width="300" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                <span>{{scope.row.projectName.split('|')||['',''][1]}}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="name" label="开票对象" width="260" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="rise" label="发票抬头"  width="260" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="amount" label="开票金额"> 
                <template slot-scope="scope">
                  <span class="colorred">￥{{fmoney(scope.row.amount,2)}}</span>
                    </template>
            </el-table-column>
            <el-table-column prop="userName" label="申请人"> </el-table-column>
            <el-table-column prop="createTime" label="开票日期">
            </el-table-column>
            <el-table-column label="开票状态">
              <template slot-scope="scope">
                <span v-if="scope.row.state == 1" class="colorgreen">已开票</span>
                <span v-if="scope.row.state == 2" class="colorblue">待开票</span>
                <span v-if="scope.row.state == 3" class="colorred">驳回</span>
                <span v-if="scope.row.state == 4" class="colorred">已作废</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-search"
                       v-if="permissoin.includes('query')"
                      :command="{ num: 2, data: scope.row }"
                      >查看</el-dropdown-item>
                      <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="scope.row.state == 2&&permissoin.includes('examine')"
                      :command="{ num: 4, data: scope.row }"
                      >审核</el-dropdown-item>
                    <!-- <el-dropdown-item
                    icon="el-icon-delete" 
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-show="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState==0">新增开票</span>
          <span v-else>开票详情</span>
          <span @click="modal = 0;form={};netArrUpdate=[{ key: 0 }];billForm={};fileList=[]" class="pointer">
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <div >
               <p>
            <span class="spaa"><span class="star">* </span>开票类型</span>
            <el-select v-model="form.type" size="mini" placeholder="请选择">
              <el-option
                v-for="item in option2"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <p v-show="form.type == 1">
            <span class="spaa"><span class="star">* </span>项目名称</span>
            <el-select
              @change="findXXById"
              v-model="form.projectId"
              clearable filterable
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option5"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <p v-show="form.type == 2">
            <span class="spaa"><span class="star">* </span>代理商</span>
            <el-select
              @change="findXXById"
              clearable filterable
              v-model="form.projectId"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option6"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
            <p v-if="modalState==0">
            <span class="spaa"><span class="star">* </span>开票信息</span>
            <el-select v-model="billForm" value-key="id" filterable size="mini" placeholder="请选择">
              <el-option
                v-for="item in option247"
                :key="item.id"              
                :label="item.rise"
                :value="item"
              >
              </el-option>
            </el-select>
          </p>
          <el-collapse v-show="billForm.rise" accordion>
  <el-collapse-item title="票面信息" name="1">
              <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>抬头</span>
            <el-input disabled v-model="billForm.rise" size="mini"></el-input>
              <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.rise)">复制</el-button>
          </p>
          <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>税号</span>
            <el-input disabled v-model="billForm.taxNum" size="mini"></el-input>
            <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.taxNum)">复制</el-button>
          </p>
          <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>地址</span>
            <el-input
              disabled
              v-model="billForm.address"
              size="mini"
            ></el-input>
            <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.address)">复制</el-button>
          </p>
          <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>电话</span>
            <el-input disabled v-model="billForm.phone" size="mini"></el-input>
            <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.phone)">复制</el-button>
          </p>
          <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>开户行</span>
            <el-input disabled v-model="billForm.bank" size="mini"></el-input>
            <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.bank)">复制</el-button>
          </p>
          <p v-show="billForm.rise" style="display:flex">
            <span class="spaa"><span class="star">* </span>账号</span>
            <el-input
              disabled
              v-model="billForm.account"
              size="mini"
            ></el-input>
            <el-button style="margin-left:6px" v-show="modalState!=0" type="primary" size="mini" @click="copyUrl(billForm.account)">复制</el-button>
          </p>
  </el-collapse-item>

</el-collapse>


          <!-- <p>
            <span class="spaa"><span class="star">* </span>发票类型</span>
            <el-select
              v-model="form.invoiceType"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p>
          <p>
            <span class="spaa"><span class="star">* </span>税率</span>
            <el-select v-model="form.taxRate" size="mini" placeholder="请选择">
              <el-option
                v-for="item in option4"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </p> -->

          <p>
            <span class="spaa"><span class="star">* </span>开票金额</span>
            <el-input v-model="form.amount" size="mini"></el-input>
          </p>
          <p  style="display:flex">
            <span class="spaa"><span class="star">* </span>出票单位</span>
            <el-select v-model="form.company" clearable filterable size="mini" placeholder="请选择">
              <el-option
                v-for="item in option8"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </p>
          <p style="display:flex">
            <span  style="width:86px" class="spa">备注</span>
            <el-input type="textarea" v-model="form.remark" size="mini"></el-input>          
          </p>
          <p v-show="form.state == 1||form.state == 2">
            <span class="spaa"><span class="star">* </span>发票编号</span>
            <el-input v-model="form.invoiceNum" :disabled="form.state == 1" size="mini"></el-input>
          </p>
                    <p v-show="form.state == 2&&modalState==4" style="display:flex">
            <span class="spaa"><span class="star">* </span>开票凭证</span>
            <el-upload
              class="upload-demo"
              :action="axiosUrl + '/admin/l/file/uploadPicture'"
              :on-success="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </p>
          <p v-show="form.state == 1">
            <span class="spa" style="min-width:75px">开票凭证</span>
             <el-image 
             
             v-for="item in srcList" :key="item"
    style="width: 100px; height: 80px;margin-right:20px"
    :src="item" 
    :preview-src-list="srcList">
  </el-image>
          </p>
          <p v-show="(form.state == 2||form.state == 3)&&modalState==4">          
              <span  class="spa">审批意见</span>
              <el-input
                      type="text"
                      size="mini"
                      v-model="form.describe"
              ></el-input>

          </p> 
          </div>
         
           <div>
          <div class="text_item">
            <span>*</span>开票明细
            <span @click="addFormUpdateItem" v-show="modalState==0">
              <i class="el-icon-plus"></i>
              增加条目</span>
          </div>
          <div class="jstitle">
            <span>名称</span>
            <span>数量</span>
            <span style="width:80px">单价</span>
            <span>发票类型</span>
            <span>税率</span>
          </div>
          <div
            class="net"
            v-for="(item, index) in netArrUpdate"
            :key="item.key"
          >
            <el-input
              v-model="item.name"
              placeholder="名称"
              size="mini"
            ></el-input>

            <el-input
              v-model="item.quantity"
              placeholder="数量"
              size="mini"
            ></el-input>
            <el-input
              v-model="item.unit"
              size="mini"
              placeholder="单价"
            ></el-input>
            <el-select
              v-model="item.invoiceType"
              size="mini"
              placeholder="类型"
            >
              <el-option
                v-for="item in option3"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="item.taxRate"
              size="mini"
              placeholder="税率"
            >
              <el-option
                v-for="item in option4"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <div style="display: flex">
              <p class="line"></p>
              <span
                class="colorred"
                style="cursor: pointer; position: relative; top: -6px"
                @click="deletenvritem(index)"
                v-show="index > 0&&modalState==0"
                ><i class="el-icon-close">删除</i></span
              >
            </div>
          </div>
          </div>

        </div>
        <div class="bottom">
          <button class="btn_red" v-show="modalState==0" @click="onsubmit">保存</button>
          <button class="btn_gray" v-show="modalState==0" @click="modal = 0">取消</button>
          <button class="btn_red" v-show="modalState==4" @click="onsubmit1(1)">通过</button>
          <button class="btn_gray" v-show="modalState==4" @click="onsubmit1(2)">驳回</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  invoiceAdd,
  invoiceExamine,
  invoiceInfo,
  invoiceGetList,
  wxGetOrgList,
  merchantGetList,
  findById,
  getIssueTicket,
  productGetList,
  axiosUrl
} from "@/api/apis.js";

export default {
  data() {
    return {
      options: [],
      form: {},

      info: 1,
      currentPage2: 1,
      treeData: [],
      hide: 1,
      axiosUrl,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      option4: [
        { name: "1%", id: 1 },
        { name: "3%", id: 3 },
        { name: "6%", id: 6 },
        { name: "9%", id: 9 },
        { name: "13%", id: 13 }
      ],
      option2: [{ name: "项目", id: 1 }, { name: "代理商", id: 2 }],
      option3: [{ name: "普通发票", id: 1 }, { name: "增值税专用发票", id: 2 }],
      modalState: 0,
      option5: [],
      option6: [],
      option8: [],
      option10: [],
      option247: [],
      fileList: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ key: 0 }],
      ruleForm: {
        pwd: 123456
      },
      billForm: {},
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true
      },
      option1: [
        {
          value: "1",
          label: "已开票"
        },
        {
          value: "2",
          label: "待开票"
        },
        {
          value: "3",
          label: "被驳回"
        },
        {
          value: "4",
          label: "已作废"
        }
      ],
      srcList: [],
      tableData: [],
      rules: {
        orgId: [{ required: true, message: "选择机构", trigger: "blur" }]
      }
    };
  },
  methods: {
    getData() {
      let data = {
        state: this.searchForm.state,
        name: this.searchForm.name,
        pageNum: this.currentPage2,
        pageSize: 10
      };
      invoiceGetList(data).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
       fmoney(val) {
      if(val){
 //金额转换 分->元 保留2位小数 并每隔3位用逗号分开 1,234.56
        var str = val.toFixed(2) + '';
        var intSum = str.substring(0,str.indexOf(".")).replace( /\B(?=(?:\d{3})+$)/g, ',' );//取到整数部分
        var dot = str.substring(str.length,str.indexOf("."))//取到小数部分搜索
        var ret = intSum + dot;
        return ret;
      }else{
        return '0'
      }    
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },
    //复制
    copyUrl(v) {
      this.$forceUpdate();
      var domUrl = document.createElement("input");
      domUrl.value = v;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      // this.$message('复制成功')
    },
    findXXById(id) {
      findById({ id }).then(res => {
        if (res.code == 200) {
          this.option247 = res.data;
          if (this.option247.length == 1) {
            this.billForm = this.option247[0];
          }
        } else {
          this.option247 = [];
          this.$message.error(res.data.msg);
        }
      });
    },
    //上传文件
    handleRemove(file, fileList) {
      let str = "";
      fileList.forEach(item => {
        str += item.response.data.url + ",";
      });
      this.form.file = str.slice(0, -1);
    },
    handlePreview(response, file, fileList) {
      let str = "";
      if (response.code == 200) {
        fileList.forEach(item => {
          str += item.response.data + ",";
        });
        this.form.file = str.slice(0, -1);
      }
    },
    deletenvritem(index) {
      this.netArrUpdate.splice(index, 1);
    },
    addFormUpdateItem() {
      this.$set(this.netArrUpdate, this.netArrUpdate.length, {
        key: this.netArrUpdate.length
      });
    },
    //审核
    onsubmit1(i) {
      if (i == 1) {
        let flag = true;
        if (this.form.file == undefined) {
          flag = false;
          this.$message("请上传开票凭证");
        }
        if (this.form.invoiceNum == undefined) {
          flag = false;
          this.$message("请填写发票编号");
        }
        if (flag) {
          invoiceExamine({
            id: this.form.id,
            state: i,
            file: this.form.file,
            invoiceNum: this.form.invoiceNum,
            describe: this.form.describe
          }).then(res => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: res.msg
              });
              this.getData();
              this.modal = 0;
              this.netArrUpdate = [{ key: 0 }];
              this.form = {};
              this.fileList = [];
              this.billForm = {};
            } else {
              this.$message.error(res.data.msg);
            }
          });
        }
      } else {
        invoiceExamine({
          id: this.form.id,
          state: i,
          file: this.form.file,
          describe: this.form.describe
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg
            });
            this.getData();
            this.modal = 0;
            this.netArrUpdate = [{ key: 0 }];
            this.form = {};
            this.fileList = [];
            this.billForm = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    onsubmit() {
      let str = "";
      this.netArrUpdate.forEach(item => {
        str += item.name + "," + item.quantity + "," + item.unit + ","+ item.invoiceType + ","+ item.taxRate + ";";
      });
      let flag = true;
      if (!this.billForm.id) {
        this.$message.error("请选择开票信息");
        flag = false;
      }
      let data = {
        type: this.form.type,
        projectId: this.form.projectId,
        invoiceInfoId: this.billForm.id,
        // invoiceType: this.form.invoiceType,
        // taxRate: this.form.taxRate,
        amount: this.form.amount,
        company: this.form.company,
        remark: this.form.remark,
        detail: str.slice(0, -1)
      };
      if (flag) {
        invoiceAdd(data).then(res => {
          if (res.code == 200) {
            this.getData();
            this.$message({
              type: "success",
              message: res.msg
            });
            this.modal = 0;
            this.netArrUpdate = [{ key: 0 }];
            this.form = {};
            this.fileList = [];
            this.billForm = {};
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
    handleCommand(d) {
      this.modalState = d.num;
      if (d.num == 2 || d.num == 4) {
        invoiceInfo({ id: d.data.id }).then(res => {
          if (res.code == 200) {
            this.form = res.data;
            this.billForm = res.data;
            if (res.data.file) {
              this.srcList = res.data.file.split(",");
            } else {
              this.srcList = [];
            }
            this.netArrUpdate = res.data.detail || [];
            this.modal = 1;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      } else if (d.num == 3) {
        this.dialogVisible = true;
      }
    }
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach(item => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    wxGetOrgList({ pageSize: 0 }).then(res => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
    productGetList({ pageSize: 0 }).then(res => {
      this.option10 = res.data.list;
    });
    merchantGetList({ pageSize: 0, type: 2 }).then(res => {
      if (res.code == 200) {
        this.option6 = res.data.list;
      }
    });
    getIssueTicket().then(res => {
      if (res.code == 200) {
        this.option8 = res.data;
      }
    });
  }
};
</script>

<style lang="less" >
.br1111222 {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 160px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 780px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
        border-bottom: 1px dashed #ebf0f5;
        font-size: 12px;
        padding-left: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .active {
        color: #466cd4;
      }
    }
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 1080px;
      height: 660px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .el-textarea__inner {
        width: 270px;
      }
      .el-collapse-item__header {
        width: 350px;
        height: 28px;
        line-height: 28px;
        margin-bottom: 10px;
        border: none;
        font-size: 12px;
        color: #000;
      }
      .el-collapse {
        border: none;
        margin-bottom: 20px;
      }
      .el-collapse-item__content {
        padding-bottom: 0px;
      }
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .jstitle {
          span {
            display: inline-block;
            margin-right: 12px;
          }
          & > span:nth-child(1) {
            width: 180px;
          }
          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
           & > span:nth-child(4) {
            width: 100px;
          }
           & > span:nth-child(3) {
            width: 60px;
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 560px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          & > .el-input:nth-child(1) {
            width: 180px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
              width: 180px;
            }
          }
           & > .el-select:nth-child(4) {
            width: 100px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
              width: 100px;
            }
          }
           & > .el-select:nth-child(5) {
            width: 60px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
              width: 60px;
            }
          }
          .el-input {
            width: 80px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
              width: 80px;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 560px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        .btn_red {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>